<template>
  <transition
    name="fade"
    mode="out-in"
  >
    <ui-loader
      key="loading"
      v-if="!appStartupLoadReady"
    />
    <div
      key="ready"
      v-else-if="appStartupLoadReady && isLoggedIn"
      class="site-wrapper"
    >
      <TopBar
        id="siteTopbar"
        class="site-topbar"
      />
      <div
        id="siteMainWrapper"
        class="site-main-wrapper"
      >
        <div
          v-if="!isLoading"
          class="main-wrapper"
        >
          <SiteMenu class="main-menu has-shadow" />

          <div
            ref="pageWrapper"
            class="page-wrapper"
          >
            <transition
              name="slide-fade-left"
              mode="out-in"
            >
              <div
                class="page-content"
                :key="$route.name"
              >
                <div class="container page-container">
                  <router-view class="manage-page-content has-padding" />
                </div>
              </div>
            </transition>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { EventBus } from '@/eventbus/event-bus'
import { mapGetters, mapState, mapMutations } from 'vuex'
import * as bulmaToast from 'bulma-toast'
import channelProvider from '@/providers/channel'
import locationProvider from '@/providers/location'

bulmaToast.setDoc(window.document)

const SiteMenu = () =>
  import(
    /* webpackChunkName: "manage" */ '@/components/MainSiteElements/SiteMenu'
  )
const TopBar = () =>
  import(
    /* webpackChunkName: "manage" */ '@/components/MainSiteElements/TopBar.vue'
  )

export default {
  components: {
    TopBar,
    SiteMenu,
  },

  data() {
    return {
      channelId: this.$route.params.channelId
        ? Number(this.$route.params.channelId)
        : 0,
      locationId: this.$route.params.locationId
        ? Number(this.$route.params.locationId)
        : 0,

      isLoading: true,
    }
  },

  computed: {
    ...mapState(['appStartupLoadReady']),
    ...mapState('channelStore', ['channel']),
    ...mapGetters({
      isLoggedIn: 'profileStore/getIsLoggedIn',
      profile: 'profileStore/getProfile',
      profileToken: 'profileStore/getProfileToken',
    }),
  },

  beforeCreate() {
    /**
     * Redirect user to login
     */
    // if (!this.$store.getters['profileStore/getIsLoggedIn']) {
    //   this.$router.push({ name: 'home' })
    //   return
    // }
    this.$nextTick().then(() => {
      if (
        this.$store.getters['getAppStartupLoadReady'] &&
        !this.$store.getters['profileStore/isLoggedIn']
      ) {
        this.$router.push({ name: 'my-account-login' })
        return
      }
      document.documentElement.style = 'overflow: hidden;'
      document.documentElement.classList.remove('frontend-environment')
      document.documentElement.classList.add('manage-environment')
    })
  },

  watch: {
    appStartupLoadReady: {
      immediate: true,
      handler: function() {
        this.checkHasAccess()
      },
    },
  },

  created() {
    EventBus.$on('showToast', (toast) => {
      this.showToast(toast)
    })

    if (this.channelId > 0) {
      // get channel state
    }

    if (this.locationId > 0) {
      // location state
      this.getLocationState()
    }
  },

  beforeDestroy() {
    EventBus.$off('showToast')
  },

  methods: {
    ...mapMutations('channelStore', [
      'setChannel',
      'setChannelState',
      'setChannelsPageState',
    ]),
    ...mapMutations('locationStore', ['setLocationState']),

    async checkHasAccess() {
      if (this.appStartupLoadReady) {
        if (!this.isLoggedIn) {
          this.$nextTick(() => this.$router.push({ name: 'home' }))
          return
        }

        this.isLoading = true

        let cResponse = await channelProvider.methods.checkChannelAccess(
          this.channelId
        )
        if (cResponse.status !== 200 || !cResponse.data) {
          this.$router.push({ name: 'my-account-dashboard' })
          return
        }

        cResponse = null
        cResponse = await channelProvider.methods.getChannelById(this.channelId)
        if (cResponse.status !== 200 || !cResponse.data) {
          this.$router.push({ name: 'my-account-dashboard' })
          return
        } else {
          // You have channel access
          this.setChannel(cResponse.data)
          if (this.$route.name === 'manage') {
            this.$router
              .push({
                name: 'channelStart',
                params: { channelId: this.channelId },
              })
              .catch()
          }
        }
      }
      this.isLoading = false
    },

    showToast(toast) {
      bulmaToast.toast({
        message: toast.message,
        duration: 3000,
        type: toast.type === 'info' ? 'is-dark' : 'is-warning',
        position: 'bottom-center',
        dismissible: true,
        closeOnClick: true,
        pauseOnHover: true,
        animate: { in: 'fadeInUp', out: 'fadeOutDown' },
      })
    },

    getLocationState() {
      locationProvider.methods
        .getLocationState(this.locationId)
        .then((response) => {
          if (response.status === 200) {
            this.setLocationState(response.data)
          }
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.site-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  .site-topbar {
    min-height: 55.5px;
  }

  .site-main-wrapper {
    height: calc(100vh - 55.5px);
  }

  .page-container {
    max-width: 1920px;
  }
}
</style>
