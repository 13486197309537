<template>
  <div class="menu-wrapper">
    <div
      class="main-menu has-shadow has-padding-bottom has-padding-top has-background-grey-light"
    >
      <Menu class="has-padding-bottom" />
      <MenuFooter />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import Menu from '@/components/UI/Menu'
import MenuFooter from '@/components/UI/MenuFooter'

export default {
  components: {
    Menu,
    MenuFooter,
  },

  data() {
    return {}
  },

  computed: {},

  methods: {},
}
</script>

<style lang="scss" scoped>
.menu-wrapper {
  display: flex;
  width: auto;
  transition: 0.75s;
  animation-duration: 0.5s;
  overflow: hidden;
  // @media screen and (max-width: 1180px) {
  //   width: 200px;
  // }
  @media screen and (max-width: 1120px) {
    overflow: hidden;
    width: 0;
    opacity: 0;
  }

  .main-menu {
    display: flex;
    flex-direction: column;
    width: 240px;
    .menu {
      width: 100%;
      flex-grow: 1;
      overflow-x: hidden;
      overflow-y: auto;
    }
  }
}
</style>
