<template>
  <aside class="menu">
    <ul v-for="menu in menus" :key="menu.Id" class="menu-list pb-5">
      <li v-for="(menuItem, index) in menu.Items" :key="'m' + index">
        <router-link
          :to="{ name: menuItem.routeName }"
          :class="{ 'is-active': setActive(menuItem) }"
        >
          {{ menuItem.label }}
        </router-link>
      </li>
    </ul>
  </aside>
</template>

<script>
import { mapState } from 'vuex'

export default {
  components: {},

  props: {},

  data() {
    return {
      showLocations: false,
      showReservations: false,
      showStart: true,
      showTerms: false,
      showVouchers: false,
      showOptions: false,

      menus: [],
    }
  },

  computed: {
    ...mapState('channelStore', ['channel']),
  },

  created() {
    this.menus.push({
      Id: 1,
      Name: 'Main',
      Items: [
        {
          label: 'Home',
          routeName: 'channelStart',
        },
        {
          label: 'Locations',
          routeName: 'locations',
        },
        {
          label: 'Reservations',
          routeName: 'reservations',
        },
        {
          label: 'Vouchers',
          routeName: 'vouchers',
        },
        {
          label: 'Options',
          routeName: 'options',
        },
        {
          label: 'Configuration',
          routeName: 'configuration',
        },
      ],
    })
    this.menus.push({
      Id: 2,
      Name: 'Subscriptions & billing',
      Items: [
        {
          label: 'Functionality bundles',
          routeName: 'bundles',
        },
        {
          label: 'Packages',
          routeName: 'packages',
        },
        {
          label: 'Subscriptions',
          routeName: 'subscriptions',
        },
        {
          label: 'Invoices',
          routeName: 'feeinvoices',
        },
      ],
    })
    this.menus.push({
      Id: 4,
      Name: 'Knowledge',
      Items: [
        {
          label: 'Stories',
          routeName: 'posts-list',
        },
        {
          label: 'Stories publishing',
          routeName: 'publish-list',
        },
        {
          label: 'Stories authors',
          routeName: 'request-list',
        },
        {
          label: 'News',
          routeName: 'news-list',
        },
      ],
    })
    this.menus.push({
      Id: 3,
      Name: 'Reports',
      Items: [
        {
          label: 'Tags',
          routeName: 'tags',
        },
        {
          label: 'Channel fees',
          routeName: 'reports-fee',
        },
      ],
    })
  },

  methods: {
    setActive(menuItem) {
      let routeNameCategory = this.$route.name.split('-')[0]
      return routeNameCategory === menuItem.routeName
    },
  },
}
</script>

<style></style>
