<template>
  <nav
    class="navbar has-shadow has-padding"
    role="navigation"
    aria-label="main navigation"
  >
    <div class="navbar-item">
      <div
        v-if="channel"
        class=""
      >
        <strong>{{ channel.Name }}</strong>
      </div>
    </div>

    <div class="navbar-menu">
      <div class="navbar-end">
        <div
          v-if="profile"
          class="navbar-item"
        >
          <LoggedInProfile size="is-small" />
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapState } from 'vuex'

const LoggedInProfile = () =>
  import(
    /* webpackChunkName: "topbar" */ '@/components/MainSiteElements/LoggedInProfile'
  )

export default {
  components: {
    LoggedInProfile
  },

  data() {
    return {
      drawerTitle: '',
      showPins: false,
      showSideDrawer: false,
      showMobileMenu: window.innerWidth < 1120,
    }
  },

  computed: {
    ...mapState('channelStore', ['channel']),
    ...mapState('profileStore', ['profile']),
  },

  methods: {},
}
</script>

<style lang="scss" scoped>
.navbar {
  padding: 5px;
  align-items: stretch;
  display: flex;
  .navbar-end {
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
  }
  .navbar-item {
    display: flex;
    align-items: center;
  }
}
</style>
