import axios from 'axios'
import store from '../store'

export default {
  methods: {
    async getChannelById(channelId) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/channel/${channelId}`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async getChannels() {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/channel`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async getChannelsProfileHasAccessTo() {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/channel/profile`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async checkChannelAccess(channelId) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/channel/${channelId}/hasaccess`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async getChannelArticlesPublishRequests(channelId) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/channel/${channelId}/articles/publish`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async acceptPublishRequests(requestId) {
      const response = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/article/request/${requestId}/accept`,
        null,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async declinePublishRequests(requestId) {
      const response = await axios.delete(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/article/request/${requestId}/decline`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async activateLocationChannel(channelId, locationId) {
      const response = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/channel/${channelId}/location/${locationId}/activate`,
        null,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async deactivateLocationChannel(channelId, locationId) {
      const response = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/channel/${channelId}/location/${locationId}/deactivate`,
        null,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async saveChannelLanguages(channelId, languages) {
      let criteria = {
        LanguageIds: languages
      }

      const response = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/channel/${channelId}/languages`,
        JSON.stringify(criteria),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async updateLocationChannelBookingTerm(channelId, locationId) {
      const response = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/channel/${channelId}/location/${locationId}/bookingterm`,
        null,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async getChannelMailTexts(channelId) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/channel/${channelId}/mailtexts`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async getChannelArticlePublishRequests(channelId) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/channel/${channelId}/article/publish/requests`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async createChannelArticlePublishRequest(channelId) {
      const response = await axios.post(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/channel/${channelId}/article/publish/request`,
        null,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async acceptChannelArticlePublishRequest(channelId, requestId) {
      const response = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/channel/${channelId}/article/publish/request/${requestId}/accept`,
        null,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async declineChannelArticlePublishRequest(channelId, requestId) {
      const response = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/channel/${channelId}/article/publish/request/${requestId}/decline`,
        null,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async deleteChannelArticlePublishRequest(channelId, requestId) {
      const response = await axios.delete(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/channel/${channelId}/article/publish/request/${requestId}`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },
  }
}
