<template>
  <div class="main-menu-footer has-padding has-text-centered">
    <!-- <div id="id_sitemenu_translations">
            <div class="tabs is-small is-toggle">
              <ul>
                <li
                  @click="setLanguage('nl')"
                  :class="{ 'is-active': $i18n.locale === 'nl' }"
                >
                  <a>
                    <span>NL</span>
                  </a>
                </li>
                <li
                  @click="setLanguage('en')"
                  :class="{ 'is-active': $i18n.locale === 'en' }"
                >
                  <a>
                    <span>EN</span>
                  </a>
                </li>
              </ul>
            </div>
          </div> -->
    <div
      class="poweredBy has-padding-top has-text-centered has-text-grey is-size-7"
    >
      <img
        src="@/assets/images/cyberdigma.png"
        alt="Cyberdigma logo"
        width="20"
        height="20"
      />
      <span>Powered by Cyberdigma</span>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    setLanguage(language) {
      this.$i18n.locale = language

      this.$localforge.set('systemLanguage', this.$i18n.locale)
    },
  },
}
</script>

<style lang="scss" scoped>
.main-menu-footer {
  border-top: 1px solid white;

  .poweredBy {
    display: flex;
    justify-content: center;
    img {
      margin-right: 5px;
    }
  }
  ul {
    justify-content: center;
  }
}
</style>
